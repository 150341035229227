import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useAppDispatch } from 'src/app/hooks'
import styles from './FilteredSolrListHeader.module.scss'
import { resetSuggesterState } from 'src/features/search-suggester'


interface FilteredSolrListHeaderProps {
  text: string
  length: number
  handleSearch: (send?: string) => void
}

const FilteredSolrListHeader: React.FC<FilteredSolrListHeaderProps> = ({ text, length, handleSearch }) => {
  const { t, i18n: { language } } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()


  const handleSearchText = () => {
    dispatch(resetSuggesterState())
    router.push(
      { pathname: '/', query: { search: text } },
      undefined,
      { locale: language || 'en' }
    )
    handleSearch(text)
  }




  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p
          className={styles.text}

          onClick={() =>
            handleSearchText()
          }
          dangerouslySetInnerHTML={{
            __html: t('Filters.Title', { text })
          }}
        />

        <p className={styles.results}>
          {t('Filters.Results', { length })}
        </p>
      </div>
    </div>
  )
}

export default FilteredSolrListHeader


