import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './ExitIntentLogged.module.scss'
import { Button, Col, Row } from 'antd'
import RequestForProposalModal from '../RequestForProposalModal/RequestForProposalModal'
import filtersImage from '../../../assets/img/exitIntentImg2.png'
import projectsImage from '../../../assets/img/exitIntentImg1.png'

interface Props {
  onClose: Function
}

const ExitIntentLogged: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()
  const [makeRequest, setMakeRequest] = useState(false)

  const handleOk = () => {
    setMakeRequest(true)
    onClose && onClose()
    
  }
  
  const handleCancel = () => {
    onClose && onClose()
  }

  return (
    <Row gutter={[0, 16]}>
      <Col className={styles['container']}>
        <div className={styles['gradient']}></div>
        <Row wrap={false}>
          <Col className={styles['info']}>
            <h1 className={styles['title']}>
              {t('exitIntentPopup.logged.title')}
            </h1>
            <h2 className={styles['subtitle']}>
              {t('exitIntentPopup.logged.subtitle')}
            </h2>
          </Col>
        </Row>
        <img
          src={projectsImage.src}
          alt='project list image'
          className={styles['image1']}
        />
        <img
          src={filtersImage.src}
          alt='project filtering panel image'
          className={styles['image2']}
        />
      </Col>

      <Col span={24}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Button
              key='submit'
              type='primary'
              onClick={handleOk}
              className={styles['ok-button']}
            >
              {t('exitIntentPopup.logged.ok')} {'->'}
            </Button>
          </Col>

          <Col span={24}>
            <Button
              key='back'
              onClick={handleCancel}
              className={styles['cancel-button']}
            >
              {t('exitIntentPopup.logged.cancel')}
            </Button>
          </Col>
        </Row>
      </Col>
      <RequestForProposalModal open={makeRequest} />
    </Row>
  )
}

export default ExitIntentLogged