/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

// Syles
import styles from './Characteristics.module.scss'
// Assets
import BLUECARBON from '../../../../assets/img/BlueCarbon@2x.png'
import BIODIVERSITY from '../../../../assets/img/Biodiversity@2x.png'
import COLOMBIACARBON from '../../../../assets/img/ColombiaCarbon@2x.png'
import CORSIA from '../../../../assets/img/Corsia@2x.png'
import ISO from '../../../../assets/img/ISO@2x.png'
import PLASTIC from '../../../../assets/img/Plastic@2x.png'
import WATER from '../../../../assets/img/Water@2x.png'
// Hooks
import { useDispatch } from 'react-redux'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setIsReset,
  setQueryWrite,
  setRefreshFilters,
  setSelectedProjectTags
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { useRouter } from 'next/router'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'

const Characteristics: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const projectTags = useAppSelector(
    (state) => state.compensation.projectTags
  )
  const selectedProjectTags = useAppSelector(
    (state) => state.filters.selectedProjectTags
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const onChangeTag = (tag: number) => {
    dispatch(setQueryWrite(true))
    if (!selectedProjectTags) {
      dispatch(checkCookies())
      dispatch(
        trackEvent(
          'MARKET_HOME',
          'ACTION_CLICK',
          'FILTER_CHARACTERTISTICS'
        )
      )
    }
    dispatch(setIsReset(true))
    const isIn = selectedProjectTags?.find((el) => el === tag)
    if (isIn) {
      const newFilter = selectedProjectTags.filter((el) => el !== tag)
      dispatch(setSelectedProjectTags(newFilter))
    } else {
      dispatch(setSelectedProjectTags([...selectedProjectTags, tag]))
    }

    dispatch(setFiltersKeys(['characteristics']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    queryFilterWriter(
      'characteristic',
      [...selectedProjectTags],
      [...projectTags],
      'id',
      'name',
      router
    )
  }, [selectedProjectTags])

  const returnImage = (tag) => {
    const tagName = tag.name_en.toLowerCase()
    if (tagName.includes('blue carbon')) {
      return BLUECARBON
    } else if (tagName.includes('biodiversity')) {
      return BIODIVERSITY
    } else if (tagName.includes('colombia carbon')) {
      return COLOMBIACARBON
    } else if (tagName.includes('corsia')) {
      return CORSIA
    } else if (tagName.includes('iso')) {
      return ISO
    } else if (tagName.includes('plastic')) {
      return PLASTIC
    } else if (tagName.includes('water')) {
      return WATER
    }
  }

  return (
    <div
      data-testid='characteristics-filter'
      className={styles['characteristics-filter']}
      tabIndex={-1}
    >
      {projectTags.map((tag, index) => {
        return (
          <Checkbox
            className={
              selectedProjectTags?.includes(tag.id)
                ? styles['text-selected']
                : styles['text']
            }
            checked={selectedProjectTags?.includes(tag.id)}
            onClick={() => onChangeTag(tag.id)}
            key={index}
          >
            <span className={styles['text-container']}>
              <img
                src={returnImage(tag)?.src}
                className={styles['characteristic-img']}
              />{' '}
              {tag.name}
            </span>
          </Checkbox>
        )
      })}
    </div>
  )
}

export default Characteristics
