import React from 'react'
import { Input } from 'antd'
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import { setName, setRefreshFilters } from 'src/features/filters/filtersSlice'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import styles from './SearchByName.module.scss'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const SearchByName: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const name = useAppSelector((state) => state.filters.name)

  const onChange = (e: any) => {
    dispatch(setName(e.target.value))
    onSearch()
  }

  const onSearch = () => {
    dispatch(checkCookies())
    dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_NAME'))
    dispatch(setFiltersKeys(['name']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  return (
    <Input
      data-testid='searchbyname-test'
      placeholder={t('mainMenu.search')}
      value={name}
      onChange={(e) => onChange(e)}
      className={styles['search-by-name']}
    />
  )
}

export default SearchByName
