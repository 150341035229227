/* eslint-disable react-hooks/exhaustive-deps */
import styles from './NeedMoreCredits.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Col, Row } from 'antd'
import { useAppSelector } from 'src/app/hooks'
import RequestForProposalModal from '../../ExitIntentPopup/RequestForProposalModal/RequestForProposalModal'
import WelcomeBackModal from 'src/components/WelcomeBackModal/WelcomeBackModal'

const NeedMoreCredits: React.FC = () => {
  const { t } = useTranslation()

  const [openRequestModal, setOpenRequestModal] = useState<boolean>(false)
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)

  const isUserLogued = useAppSelector((state) => state.user.isUserLogued)

  const handleClick = () => {
    if (isUserLogued) {
      setOpenRequestModal(true)
    } else {
      setOpenLoginModal(true)
    }
  }

  useEffect(() => {
    const openModal = JSON.parse(sessionStorage.getItem('show_rfp'))

    if (openModal && isUserLogued) {
      setOpenRequestModal(true)
    }
  })

  const closeRequestModal = () => {
    sessionStorage.setItem('show_rfp', 'false')
    setOpenRequestModal(false)
  }

  return (
    <>
      <Row className={styles['container']}>
        <Col span={24} className={styles['layout']}>
          <Row>
            <Col>
              <Row>
                <h1 className={styles['title']}>
                  {t('whatsNewBanner.needMoreCredits.title')}
                </h1>
              </Row>

              <Row align='middle' justify='space-between'>
                <h2 className={styles['subtitle']}>
                  {t(
                    'whatsNewBanner.needMoreCredits.subtitle'
                  )}
                </h2>
              </Row>
            </Col>
          </Row>

          <Row>
            <Button
              className={styles['button']}
              onClick={handleClick}
            >
              {t('whatsNewBanner.needMoreCredits.button')}
            </Button>
          </Row>
        </Col>
      </Row>

      {isUserLogued && (
        <RequestForProposalModal
          open={openRequestModal}
          onClose={closeRequestModal}
        />
      )}
      <WelcomeBackModal
        isModalVisible={openLoginModal}
        handleCancel={() => setOpenLoginModal(false)}
      />
    </>
  )
}

export default NeedMoreCredits
