import React from 'react'
import { Modal } from 'antd'
import styles from './ModalHowWorks.module.scss'
import { useTranslation } from 'next-i18next'

interface Props {
  isModalOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsModalOpen: (arg0: boolean) => void;
}

const ModalHowWorks: React.FC<Props> = ({
  setIsModalOpen,
  isModalOpen
}) => {

  const { t, i18n } = useTranslation()

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal title={
      <p className={styles['title']}>{t('modalHowWorks.title')}</p>
    }
    className={styles['modal']}
    visible={isModalOpen}
    onCancel={handleCancel}
    footer={null}
    wrapClassName={styles['modal-wrap']}
    width={600}>
      <div className={styles['container']}>
        <div className={styles['degrade-left']}></div>
        <div className={styles['degrade-top']}></div>
        <div className={styles['text-container']}>
          <h3 className={
            i18n.language == 'de' || i18n.language == 'pt' || i18n.language == 'fr' ? styles['modal-title-l'] :
              styles['modal-title']
          }>{t('modalHowWorks.image-title')}</h3>
          <p className={styles['modal-text']}>{t('modalHowWorks.image-text')}</p>
        </div>
        <div className={styles['offsetting-container']}>
          <p className={styles['text-tag']}>{t('modalHowWorks.tag-text')}</p>
          <div className={styles['icon-container']}>
            <div className={styles['icon-tag']}>
              <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M12.653 12.2134L9.63609 8.44215H11.8988L8.88185 4.67095H10.3903L6.61913 0.145508L2.84793 4.67095H4.35641L1.33945 8.44215H3.60217L0.585205 12.2134H5.86489V15.2303H7.37337V12.2134H12.653Z' fill='#3DD598'/>
              </svg>
            </div>
            <div className={styles['number-tag']}>+6</div>

          </div>
        </div>
      </div>

      <div className={styles['par-container-gral']}>
        <div className={styles['par-container']}>
          <div className={styles['title-container']}>
            <div className={styles['number-icon']}>
              <p className={styles['number-icon-text']}>1</p>
            </div>
            <p className={styles['title']}>{t('modalHowWorks.select-pr')}</p>
          </div>
          <div>
            <p className={styles['par-text']}>{t('modalHowWorks.par-text')}</p>
          </div>
        </div>

        <div className={styles['par-container']}>
          <div className={styles['title-container']}>
            <div className={styles['number-icon']}>
              <p className={styles['number-icon-text']}>2</p>
            </div>
            <p className={styles['title']}>{t('modalHowWorks.compensate')}</p>
          </div>
          <div>
            <p className={styles['par-text']}>{t('modalHowWorks.par-text2')}</p>
          </div>
        </div>
      </div>

      <div className={styles['why-climate']}>
        <p className={styles['title']}>{t('modalHowWorks.why-climate')}</p>

        <p className={styles['par-text']}>{t('modalHowWorks.why-text')}</p>
      </div>
    </Modal>
  )
}

export default ModalHowWorks
