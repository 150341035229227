import styles from './Background.module.scss'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { Col, Row, Skeleton } from 'antd'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { ArrowRightOutlined } from '@ant-design/icons'
import ModalHowWorks from '../ModalHowWorks/ModalHowWorks'
import { trackEvent } from 'src/features/visitors/actions'
import { setTrackHowItWorks } from 'src/features/ui/uiSlice'

interface Props {}

const Background: React.FC<Props> = () => {
  const { t } = useTranslation()
  const loading = useAppSelector((state) => state.ui.loading)
  const trackHowItWorks = useAppSelector((state) => state.ui.trackHowItWorks)
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const dispatch = useAppDispatch()

  const showModal = () => {
    if (!trackHowItWorks) {
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'OPEN_HOW_IT_WORKS'))
      dispatch(setTrackHowItWorks(true))
    }
    setIsModalOpen(true)
  }

  return (
    <>
      <Row data-testid='slider'>
        <Col span={24}>
          <div className={styles['container']}>
            <div className={styles['degrade-left']}></div>
            <div className={styles['degrade-right']}></div>
            <div className={styles['divTitle']}>
              <h1 className={styles['titleBack']}>
                {loading ? (
                  t('background.title')
                ) : (
                  <Skeleton
                    active={true}
                    paragraph={{ rows: 2, width: [200] }}
                  />
                )}
              </h1>

              <div className={styles['divBtns']}>
                {loading ? (
                  <button
                    id='btnShopNow'
                    className={styles['btnShopNow']}
                    onClick={showModal}
                  >
                    {t('background.btnShopNow')}
                  </button>
                ) : (
                  <Skeleton.Button
                    active={true}
                    size={'default'}
                    shape={'round'}
                    block={false}
                  />
                )}
                {loading ? (
                  <div>
                    <div className={styles['btnCalculate']}>
                      <a
                        id='btnCalculate'
                        target='_blank'
                        rel='noreferrer'
                        href={`${window.location.origin}/carbon-footprint-calculator`}
                        className={styles['linkCalculate']}
                      >
                        {t('background.btnCalculate')}
                        &nbsp; &nbsp;
                      </a>
                      <ArrowRightOutlined style={{ color: 'white' }} />
                    </div>
                    <div className={styles['btnCalculateMobile']}>
                      <a
                        id='btnCalculate'
                        target='_blank'
                        rel='noreferrer'
                        href={`${window.location.origin}/carbon-footprint-calculator`}
                        className={styles['linkCalculateMobile']}
                      >
                        {t('background.footprintMobile')}
                      </a>
                    </div>
                  </div>
                ) : (
                  <Skeleton.Button
                    active={true}
                    size={'default'}
                    shape={'round'}
                    block={false}
                  />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ModalHowWorks
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
    </>
  )
}

export default Background
