import { Skeleton } from 'antd'
import React from 'react'
import styles from './SkeletonFilters.module.scss'

const SkeletonFilters: React.FC = () => {
  return (
    <div>
      <div className={styles['contSkeleton']}>
        <Skeleton.Input
          active
          className={styles['inputSkeletonFilter']}
        ></Skeleton.Input>
        <Skeleton.Avatar></Skeleton.Avatar>
        <br />
        <br />
        <br />
        <Skeleton.Input
          active
          className={styles['inputSkeletonFilter']}
        ></Skeleton.Input>
        <Skeleton.Avatar></Skeleton.Avatar>
        <br />
        <br />
        <br />
        <Skeleton.Input
          active
          className={styles['inputSkeletonFilter']}
        ></Skeleton.Input>
        <Skeleton.Avatar></Skeleton.Avatar>
        <br />
        <br />
        <br />
        <Skeleton.Input
          active
          className={styles['inputSkeletonFilter']}
        ></Skeleton.Input>
        <Skeleton.Avatar></Skeleton.Avatar>
      </div>
    </div>
  )
}

export default SkeletonFilters
