import React from 'react'
import Image from 'next/image'
import { useHover } from 'src/app/hooks'
import { useTranslation } from 'next-i18next'
import * as images from 'src/assets/img/filters'
import styles from './FilteredSolrItem.module.scss'
import { SuggesterFilterItem } from 'src/interfaces'
import { getHighlightedText } from 'src/helpers/strings.helpers'

interface FilteredSolrItemProps {
  item: SuggesterFilterItem
  text: string
}

const FilteredSolrItem: React.FC<FilteredSolrItemProps> = ({ item, text }) => {
  const { t } = useTranslation()
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const filterType = {
    projects: { hover: images.ProjectBlue, no_hover: images.Project, text: t('Filters.Project') },
    results: { hover: images.MarketBlue, no_hover: images.Market, text: t('Filters.Marketplace') },
    pages: { hover: images.DocumentBlue, no_hover: images.Document, text: t('Filters.BlogArticle') },
    ['Web page']: { hover: images.DocumentBlue, no_hover: images.Document, text: t('Filters.WebPage') },
    ['Blog article']: { hover: images.DocumentBlue, no_hover: images.Document, text: t('Filters.BlogArticle') },
  }[item.type]

  return (
    <div className={styles.content} ref={hoverRef}>

      {/* Image */}
      <div className={styles.image}>
        <Image
          src={isHovered ? filterType.hover : filterType.no_hover}
          alt='icon'
        />
      </div>

      {/* Texts */}
      <div className={styles.texts}>
        <span
          className={styles.label}
          dangerouslySetInnerHTML={{
            __html: getHighlightedText(item.label, text)
          }}
        />

        <p className={styles.typeText}>
          {filterType.text}
        </p>
      </div>
    </div>
  )
}

export default FilteredSolrItem
