import React, { useEffect } from 'react'

// Syles
import styles from './Mechanism.module.scss'
// Hooks
import { useDispatch } from 'react-redux'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setIsReset,
  setRefreshFilters,
  setSelectedMechanism,
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'
import { useRouter } from 'next/router'

const Mechanism: React.FC = () => {
  const dispatch = useDispatch()

  const router = useRouter()
  const mechanism = useAppSelector((state) => state.compensation.mechanism)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const mechanismSelected = useAppSelector(
    (state) => state.filters.selectedMechanism
  )

  const onChangeMechanism = (mechanism: any) => {
    if(!mechanismSelected){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_MECHANISM'))
    }
    dispatch(setIsReset(true))
    const isIn = mechanismSelected?.find((el) => el === mechanism)
    if (isIn) {
      const newFilter = mechanismSelected?.filter((el) => el !== mechanism)
      dispatch(setSelectedMechanism(newFilter))
    } else {
      dispatch(
        setSelectedMechanism(
          mechanismSelected ? [...mechanismSelected, mechanism] : [mechanism]
        )
      )
    }
    dispatch(setFiltersKeys(['mechanism']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
  useEffect(() => {
    const query ={ ...router.query, mechanism: mechanismSelected }
    router.replace({ pathname: router.pathname, query }, undefined, { scroll: false })
    queryFilterWriter('mechanism',
      mechanismSelected.map(m=>({ name: 'mechanism', value: m }))
      , null, 'mechanism', 'value', router)
  }, [mechanismSelected])
  return (
    <div data-testid='mechanism-filter' className={styles['standard-filter']}>
      {mechanism?.map((mechanism, index) => {
        return (
          <Checkbox
            className={
              mechanismSelected?.includes(mechanism)
                ? styles['standard-text-selected']
                : styles['standard-text']
            }
            checked={mechanismSelected?.includes(mechanism)}
            onClick={() => onChangeMechanism(mechanism)}
            key={index}
          >
            {mechanism}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default Mechanism
