import styles from './CardProjects.module.scss'
import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { Currency, Project } from '../../interfaces/InterfaceGlobal'
import { ProjectCard } from '@climatetrade/components'
import Cookie from 'js-cookie'
import { useTranslation } from 'next-i18next'

interface PropsType {
  project: Project;
  currencyList: Currency[];
}

const CardProjects = ({ project, currencyList }: PropsType) => {
  const { i18n } = useTranslation()
  const currency = useAppSelector((state) => state.compensation.currency_code)
  const filteredProjects = useAppSelector(
    (state) => state.filters?.filteredProjects
  )
  //const project = useAppSelector((state) => state.project.project)

  const handleProjectCardClick = () => {
    Cookie.remove('_p_id', { expires: 720 })
    Cookie.remove('_p_name', { expires: 720 })
    Cookie.remove('_p_cover', { expires: 720 })
    Cookie.remove('_p_c_name', { expires: 720 })
    Cookie.remove('_p_kind', { expires: 720 })
    Cookie.remove('myCookies', { expires: 720 })
    Cookie.set('_p_c_name', project.country.name, { expires: 720 })
    Cookie.set('_p_id', `${project.id}`, { expires: 720 })
    Cookie.set('_p_name', project.name, { expires: 720 })
    Cookie.set('_p_cover', project.cover, { expires: 720 })
    Cookie.set('_p_kind', project.kind, { expires: 720 })



  }

  return (
    // <div className={styles['container']}>
    <div
      className={
        filteredProjects?.length < 3
          ? styles['card-container-2']
          : styles['card-container']
      }
    >
      <a
        href={`${window.location.origin}/projects/${i18n.language}/${project.slug}?id=${project.id}`}
        className={styles['redirectionLink']}
        onClick={
          handleProjectCardClick
        }
      >
        <div className={styles['cardDesktop']}>
          <ProjectCard
            project={project}
            currencyList={currencyList}
            userCurrencyCode={currency}
            useFinalPrice={true}
            noConvert={true}
            dimensionsHeight={312}
            dimensionsWidth={288}
            handleProjectDetailsRedirection={handleProjectCardClick}
          />
        </div>
        <div className={styles['cardMobile']}>
          <ProjectCard
            project={project}
            currencyList={currencyList}
            userCurrencyCode={currency}
            useFinalPrice={true}
            noConvert={true}
            dimensionsHeight={224}
            dimensionsWidth={328}
            handleProjectDetailsRedirection={handleProjectCardClick}
          />
        </div>
      </a>
    </div>
    // </div>
  )
}

export default CardProjects
