import styles from './FootprintCalculator.module.scss'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Col, Row } from 'antd'


const FootprintCalculator: React.FC = () => {
  const { t } = useTranslation()

  const handleClick = () => {
    window.open('https://market.climatetrade.com/carbon-footprint-calculator', '_blank')
  }

  return (
    <Row className={styles['container']}>
      <Col span={24} className={styles['layout']}>
        <Row>
          <Col>
            <Row>
              <h1 className={styles['title']}>
                <span>{t('whatsNewBanner.footprintCalculator.title')}</span>
              </h1>
            </Row>

            <Row align='middle' justify='space-between'>
              <h2 className={styles['subtitle']}>
                {t('whatsNewBanner.footprintCalculator.subtitle')}
              </h2>
            </Row>
          </Col>
        </Row>

        <Row>
          <Button className={styles['button']} onClick={handleClick}>
            {t('whatsNewBanner.footprintCalculator.button')}
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default FootprintCalculator
