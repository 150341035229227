/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from 'antd'
import React, { useEffect } from 'react'
import { useAppSelector } from 'src/app/hooks'
import { Collapse } from 'antd'
const { Panel } = Collapse

// Syles
import styles from './CategoryType.module.scss'
import { useDispatch } from 'react-redux'
import {
  setIsReset,
  setQueryWrite,
  setRefreshFilters,
  setSelectedTypes
} from 'src/features/filters/filtersSlice'
import ProjectType from '../ProjectType/ProjectType'
import { useRouter } from 'next/router'
import { UpOutlined } from '@ant-design/icons'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'

const CategoryType: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  const listOfCategories = useAppSelector(
    (state) => state.compensation.differentCategories
  )
  const typesSelected = useAppSelector((state) => state.filters.selectedTypes)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const onChangeCategory = (category) => {
    dispatch(setQueryWrite(true))
    dispatch(setIsReset(true))
    const totalPerCategorySelected = typesSelected.filter(
      (el) => el.category == category
    )
    const categorySelected = typesSelected.filter(
      (el) => el.category !== category
    )
    if (!typesSelected) {
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_TYPE'))
    }
    if (totalPerCategorySelected.length > 0) {
      dispatch(setSelectedTypes(categorySelected))
    } else {
      const typesInCategory = listOfCategories.find(
        (el) => el.category == category
      )
      const totalPerCategory = typesInCategory.options.filter(
        (el) => el.category == category
      )
      dispatch(
        setSelectedTypes([...categorySelected, ...totalPerCategory])
      )
    }
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const isCheckedCategory = (category) => {
    return typesSelected?.find((el) => el.category == category)
  }

  useEffect(() => {
    queryFilterWriter('type', typesSelected, null, 'id', 'value', router)
  }, [typesSelected])

  return (
    <>
      {listOfCategories?.filter(({ category })=>category)?.map((item, index) => {
        return (
          <div className={styles['category-container']} key={index}>
            <Checkbox
              className={styles['category-checkbox']}
              checked={isCheckedCategory(item.category)}
              onClick={() => onChangeCategory(item.category)}
              key={index}
            />
            <Collapse
              className={styles['collapse-category']}
              bordered={false}
              expandIconPosition='right'
              expandIcon={({ isActive }) => (
                <UpOutlined rotate={isActive ? 0 : 180} style={{ color: '#777E8B' }} />
              )}
              ghost={true} defaultActiveKey={item.category}
            >
              <Panel
                header={
                  <p className={styles['category-text'+isCheckedCategory(item.category) ? '-selected':'']}>
                    {item.category}
                  </p>
                }
                key={item.category }
              >
                <ProjectType types={item.options} />
              </Panel>
            </Collapse>
          </div>
        )
      })}
    </>
  )
}

export default CategoryType
