/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styles from './SDGs.module.scss'
import { Checkbox } from 'antd'
import { useTranslation } from 'next-i18next'
import { getSDGs } from 'src/features/projects/projectsAPI'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import {
  setIsReset,
  setQueryWrite,
  setRefreshFilters,
  setSDGs
} from 'src/features/filters/filtersSlice'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { useRouter } from 'next/router'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'

interface Sdg {
    id: number
    name: string
    icon: string
}

const SDGs: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const dispatch = useDispatch()
  const [sdgs, setSdgs] = useState<Array<Sdg>>([])
  const SDGsRedux = useAppSelector((state) => state.filters.SDGs)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  //const queryLoaded = useAppSelector((state) => state.filters.queryLoaded)
  const loading = useAppSelector((state) => state.ui.loading)

  const onChangeSDG = (sdg: any) => {
    dispatch(setQueryWrite(true))
    if (!SDGsRedux) {
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_SDG'))
    }
    dispatch(setIsReset(true))
    const isIn = SDGsRedux?.find((el) => el === sdg.id)
    if (isIn) {
      const newFilter = SDGsRedux.filter((el) => el !== sdg.id)
      dispatch(setSDGs(newFilter))
    } else {
      dispatch(setSDGs([...SDGsRedux, sdg.id]))
    }
    dispatch(setFiltersKeys(['SDG']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    sdgs.length !== 0 &&
            queryFilterWriter(
              'sdg',
              [...SDGsRedux],
              [...sdgs],
              'id',
              'name',
              router
            )
  }, [SDGsRedux])

  useEffect(() => {
    getSDGs()
      .then((res) => setSdgs(res.results))
      .catch((e) => console.warn(e))
  }, [loading])

  return (
    <div data-testid='sdg-filter'>
      {sdgs?.map((sdg, index) => {
        return (
          <Checkbox
            value={sdg.id}
            checked={SDGsRedux.includes(sdg.id)}
            key={index}
            className={styles['divRadio']}
            onClick={() => onChangeSDG(sdg)}
          >
            <div className={styles['divContenedor']}>
              <img
                src={sdg.icon}
                className={styles['sdg-icon']}
              />
              <div className={styles['divSdg']}>
                <span
                  title={sdg.name}
                  className={
                    SDGsRedux.includes(sdg.id)
                      ? styles['sdg-text-selected']
                      : styles['sdg-text']
                  }
                >
                  {sdg.id}. {t(`SDG.${sdg.id}`)}
                </span>
              </div>
            </div>
          </Checkbox>
        )
      })}
    </div>
  )
}

export default SDGs
