/* eslint-disable no-unused-vars */
import { useTranslation } from 'next-i18next'
import React from 'react'
import styles from './CategoryTag.module.scss'

interface Props {
  categoryName: string;
  handleCategory: (arg0: string) => void;
  countProjects: (arg0: string) => string;
}

const CategoryTag: React.FC<Props> = ({
  categoryName,
  handleCategory,
  countProjects,
}) => {
  const { t } = useTranslation()

  const label = {
    Compensation: t('card.carbon'),
    Contribution: t('card.nature'),
    Energy: t('card.renewable'),
    Portfolio: t('card.Portfolio'),
    Carbon_forward: t('Carbon forward'),
  }[categoryName] || ''

  return (
    <div
      data-testid='category-tag'
      className={styles['category-tag']}
      onClick={() => handleCategory(categoryName)}
    >
      <h2 className={styles['category-text']}>
        {label}

        <span className={styles['results']}>
          ({countProjects(categoryName)})
        </span>
      </h2>
    </div>
  )
}

export default CategoryTag
