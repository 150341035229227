import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'next-i18next'
import styles from './NewsSubscriptionForm.module.scss'
import { postNewsSuscription } from 'src/features/users/usersAPI'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'src/app/hooks'
import { trackEvent } from 'src/features/visitors/actions'

interface Props {
  onFinish: Function;
}

const NewsSubscriptionForm: React.FC<Props> = ({ onFinish }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const router = useRouter()
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [captcha, setCaptcha] = useState<string>(null)
  const [showCaptchaError, setShowCaptchaError] = useState<boolean>(null)

  const [form] = Form.useForm()

  const siteKey = process.env.RECAPTCHA_PUBLIC_KEY

  const handleSend = async () => {
    try {
      if (!captcha) {
        setShowCaptchaError(true)
        return
      }

      const values = await form.validateFields()

      const countryCode = router.locale.split('-')[1]

      const suscription = {
        lastName: values.email,
        email: values.email,
        countryCode: countryCode,
        language: i18n.language,
        newsletter: 'true',
        gRecaptchaResponse: captcha,
      }

      postNewsSuscription(suscription)

      onFinish()
    } catch (error) {
      console.error('Error de validación del formulario:', error)
    }

    handleTrackEvent()
  }

  const handleTrackEvent = () => {
    dispatch(trackEvent(
      'MARKET_HOME',
      'ACTION_CLICK',
      'SUBSCRIBE_NEWSLETTER'
    ))
  }

  const handleShowCaptcha = () => {
    setShowCaptcha(true)
  }

  return (
    <Form form={form} name='suscriptionForm' onFinish={handleSend}>
      <div className={styles['inputContainer']}>
        <Form.Item
          name='email'
          rules={[
            {
              type: 'email',
              message: t('exitIntentPopup.notLogged.emailError'),
            },
            {
              required: true,
              message: t('exitIntentPopup.notLogged.emailRequired'),
            },
          ]}
        >
          <Input
            placeholder={t('exitIntentPopup.notLogged.emailPlaceholder')}
            className={styles['emailInput']}
            onChange={handleShowCaptcha}
          />
        </Form.Item>

        <Form.Item className={styles['sendButtonContainer']}>
          <Button
            type='primary'
            htmlType='submit'
            className={styles['sendButton']}
          >
            {t('exitIntentPopup.notLogged.send')}
          </Button>
        </Form.Item>
      </div>

      <Form.Item className={styles['recaptchaContainer']} hidden={!showCaptcha}>
        {siteKey && (
          <ReCAPTCHA
            hl={i18n.language}
            sitekey={siteKey}
            onChange={(token: string) => setCaptcha(token)}
            className={styles['recaptchaContainer']}
          />
        )}
      </Form.Item>
      {showCaptchaError && (
        <div className={styles['captchaError']}>
          <span>{t('exitIntentPopup.notLogged.captchaRequired')}</span>
        </div>
      )}
    </Form>
  )
}

export default NewsSubscriptionForm
