/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Filters from '../Filters'
import CategoryFilter from '../../CategoryFilter/CategoryFilter'
import styles from './ModalFilters.module.scss'
import menuOutlined from '../../../assets/img/menuOutlined.png'
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core'

import {
  setInitialState,
  setIsReset,
  setPrice,
  setPriceChange,
  setRefreshFilters,
  setSearch,
  setStock,
  setStockChange,
  setYearChange,
  setYearSince,
  setYearUntil,
} from 'src/features/filters/filtersSlice'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'src/app/hooks'
import {
  getExpensivePrice,
  getHigherStock,
  getNewestVintage,
  getOlderVintage,
} from 'src/helpers/utils'
import {
  setMaxPrice,
  setMaxStock,
} from 'src/features/compensation/compensationSlice'
import { useTranslation } from 'next-i18next'
import { Collapse, Select } from 'antd'
import router from 'next/router'

interface Props {
  changeSort: Function;
}

const ModalFilter: React.FC<Props> = ({ changeSort }) => {
  const { t, i18n } = useTranslation()
  const [newOrder, setNewOrder] = useState<string>('-scoring')
  const [buttonFilter, setButtonFilter] = useState<boolean>(false)
  const [applyReset, setApplyReset] = useState<boolean>(false)
  const dispatch = useDispatch()
  const projects = useAppSelector((state) => state.project.project)
  const order = useAppSelector((state) => state.filters.order)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  useEffect(() => {
    setNewOrder(order)
  }, [])

  const resetFilters = () => {
    sessionStorage.setItem('filters', null)
    dispatch(setInitialState())
    dispatch(setYearSince(getOlderVintage(projects?.results)))
    const currentYear = new Date().getFullYear()
    const newestVintageYear = getNewestVintage(projects?.results)
    dispatch(setYearUntil(currentYear > newestVintageYear ? currentYear : newestVintageYear))
    dispatch(setYearChange(false))
    dispatch(setPriceChange(false))
    dispatch(setStockChange(false))
    dispatch(setMaxStock(getHigherStock(projects?.results)))
    dispatch(setStock([0, getHigherStock(projects?.results)]))
    dispatch(setMaxPrice(getExpensivePrice(projects?.results).price))
    dispatch(setPrice([0.01, getExpensivePrice(projects?.results).price]))
    dispatch(setSearch(''))
    dispatch(setIsReset(true))
    router.push(`/`, undefined, {
      locale: i18n.language, scroll: false
    })
    //changeSort('-scoring');
    setApplyReset(true)
    dispatch(setRefreshFilters(!refreshFilters))
  }


  const handleSortTitle = () => {
    switch (order) {
      case '-scoring':
        return t('filters.recommended')
      case '-price':
        return t('filters.majorPrice')
      case 'price':
        return t('filters.minorPrice')
      case '-name':
        return `${t('filters.name')} (Z - A)`
      case 'name':
        return `${t('filters.name')} (A - Z)`

      default:
        return t('filters.recommended')
    }
  }

  const handleChangeSort = (value) => {
    if (applyReset) {
      setApplyReset(false)
    }
    setNewOrder(value)
  }

  const handleApply = () => {
    if (applyReset) {
      changeSort('-scoring')
    } else {
      changeSort(newOrder)
    }
    setButtonFilter(!buttonFilter)
  }

  return (
    <div className={styles['modalContainer']} data-testid='modal-filter-test'>
      <div className={styles['iconFilterMobile']}>
        <CategoryFilter />
        &nbsp; &nbsp;&nbsp; &nbsp;
        <img
          src={menuOutlined.src}
          className={styles['menuOutlined']}
          onClick={() => setButtonFilter(!buttonFilter)}
        />
      </div>

      <Dialog
        fullScreen
        open={buttonFilter}
        //onClose={() => setButtonFilter(!buttonFilter)}
      >
        <div className={styles['titleContainer']}>
          <IconButton
            onClick={() => setButtonFilter(!buttonFilter)}
            className={styles['closeButton']}
            color='inherit'
            aria-label='close'
          >
            <ArrowBackIosIcon fontSize='small' />
          </IconButton>
          <span className={styles['title']}>{t('modalFilters.title')}</span>
        </div>

        <DialogContent className={styles['modalBody']}>
          <div className={styles['filtros']}>
            <Collapse bordered={false} expandIconPosition='right' ghost={true}>
              <Collapse.Panel
                header={
                  <div className={styles['container-category']}>
                    {`${t('filters.sort')} ${handleSortTitle()}`}
                  </div>
                }
                className={styles['filter-text']}
                key='sort'
              >
                <div className={styles['input-container']}>
                  <Select
                    defaultValue={order}
                    value={newOrder}
                    className={styles['type-filter']}
                    onChange={handleChangeSort}
                    dropdownStyle={{ zIndex: 2000 }}
                    options={[
                      {
                        value: '-scoring',
                        label: t('filters.recommended'),
                      },
                      {
                        value: '-price',
                        label: t('filters.majorPrice'),
                      },
                      {
                        value: 'price',
                        label: t('filters.minorPrice'),
                      },
                      {
                        value: 'name',
                        label: 'A - Z',
                      },
                      {
                        value: '-name',
                        label: 'Z - A',
                      },
                    ]}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
            <Filters />
          </div>
        </DialogContent>
        <DialogActions className={styles['actionsContainer']}>
          <div className={styles['btnContainer']}>
            <button className={styles['btnClear']} onClick={resetFilters}>
              {t('modalFilters.reset')}
            </button>
            <button className={styles['btnSave']} onClick={handleApply}>
              {t('modalFilters.apply')}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModalFilter
