import { Input } from 'antd'
import { useRouter } from 'next/router'
import styles from './Search.module.scss'
import { ParsedUrlQuery } from 'querystring'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { i18n, useTranslation } from 'next-i18next'
import { filterStateSelector } from 'src/features'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import FilteredItemsList from './FilteredItemsList/FilteredItemsList'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { suggesterStateSelector } from 'src/features/search-suggester/slice'
import { filterByTextSuggester, resetSuggesterState } from 'src/features/search-suggester'
import { setIsReset, setRefreshFilters, setSearch } from 'src/features/filters/filtersSlice'

const Search: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const language = i18n?.language || 'en'

  const { refreshFilters, search } = useAppSelector(filterStateSelector)
  const { data: filteredItems } = useAppSelector(suggesterStateSelector)

  const [searchValue, setSearchValue] = useState<string | string[]>()

  useEffect(() => {
    if (router.query?.search) { setSearchValue(router.query?.search) }
  }, [router?.query])

  useEffect(() => {
    if (search === '') { setSearchValue('') }
  }, [search])

  const handleInput = (e?: any) => {
    const queryParam = new URLSearchParams(window.location.search)
    const query: ParsedUrlQuery = {}
    for (const key of Array.from(queryParam.keys())) { query[key] = queryParam.getAll(key) }
    if (e.keyCode !== 13) return

    const value = e.target.value
    dispatch(setIsReset(false))
    dispatch(resetSuggesterState())
    sendSearch(value, query)
  }



  const handleSearchChange = (value: any) => {
    setSearchValue(value)
    if (!value) {
      dispatch(resetSuggesterState())
    }
    dispatch(filterByTextSuggester(value, language))

  }
  const handleClickSearch = (send: string) => {
    const queryParam = new URLSearchParams(window.location.search)
    const query: ParsedUrlQuery = {}
    for (const key of Array.from(queryParam.keys())) { query[key] = queryParam.getAll(key) }
    sendSearch(send, query)
  }

  const sendSearch = (value: string, query: any) => {
    if (value) {
      setSearchValue(value)
      dispatch(setSearch(value))
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'SEARCH_BOX', value))
      router.push({ pathname: `/`, query: { ...query, search: value } }, undefined, { locale: i18n.language })
      dispatch(setRefreshFilters(!refreshFilters))
    } else {
      setSearchValue(null)
      dispatch(setSearch(null))
      router.push(`/`, undefined, {
        locale: i18n.language,
      })
      dispatch(setRefreshFilters(!refreshFilters))
      return
    }
  }

  return <>
    <div data-testid='search' className={styles.search}>
      <Input
        size='large'
        value={searchValue}
        placeholder={t('mainMenu.search')}
        className={styles['search-input']}
        onKeyDown={(e) => handleInput(e)}
        onChange={(e) => handleSearchChange(e.target.value)}
        prefix={<SearchOutlined style={{ marginRight: '10px' }} />}
      />
    </div>

    {filteredItems.length > 0 && (
      <FilteredItemsList
        text={searchValue as string}
        filteredItems={filteredItems}
        handleSearch={(send) => handleClickSearch(send)}
      />
    )}
  </>
}

export default Search
