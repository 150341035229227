/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

// Styles
import styles from './Year.module.scss'
// Components
import { Select } from 'antd'
const { Option } = Select
// Hooks
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
// Redux
import {
  setRefreshFilters,
  setYearChange,
  setYearSince,
  setYearUntil,
} from 'src/features/filters/filtersSlice'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { getNewestVintage, getOlderVintage } from 'src/helpers/utils'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const Year: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const yearSince = useAppSelector((state) => state.filters.yearSince)
  const yearUntil = useAppSelector((state) => state.filters.yearUntil)
  const yearChange = useAppSelector((state) => state.filters.yearChange)
  const projects = useAppSelector((state) => state.project.project)
  const [years, setYears] = useState<Array<number>>([])
  const [listYearsUntil, setListYearsUntil] = useState<Array<number>>(years)
  const [listYearsSince, setListYearsSince] = useState<Array<number>>([])

  // Get a list of project's years
  const getYearRate = (year?: number) => {
    let arrayOfYears = []
    const date = getNewestVintage(projects?.results)
    for (let i = year ? year : yearSince; i <= date; i++) {
      arrayOfYears.push(i)
    }
    setYears(arrayOfYears.sort())
    if (yearUntil) {
      const newListSince = years.filter(year => year <= yearUntil)
      setListYearsSince(newListSince.sort())
    } else {
      setListYearsSince(arrayOfYears.sort())
    }
    setListYearsUntil(arrayOfYears.sort())
  }

  // Get a list of project's years equal or bigger than the "since year" selected
  const getYearUntil = (yearSelected: number, arrayUntil?: any) => {
    let arrayOfYears = []
    if (arrayUntil) {
      arrayUntil?.forEach((y) => {
        if (y >= yearSelected) {
          arrayOfYears.push(y)
        }
      })
    } else {
      years?.forEach((y) => {
        if (y >= yearSelected) {
          arrayOfYears.push(y)
        }
      })
    }
    setListYearsUntil(arrayOfYears.sort())
  }

  const handleChangeSince = (value: number) => {
    if (!yearChange) {
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_YEAR'))
    }
    dispatch(setYearSince(value))
    getYearUntil(value)
    dispatch(setYearChange(true))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const handleChangeUntil = (value: number) => {
    dispatch(setYearUntil(value))
    const newListSince = years.filter(year => year <= value)
    setListYearsSince(newListSince)
    dispatch(setFiltersKeys(['year']))
    dispatch(setYearChange(true))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    const date = getNewestVintage(projects?.results)
    if (yearSince) {
      let arrayOfYears = []
      for (let i = getOlderVintage(projects?.results); i <= date; i++) {
        arrayOfYears.push(i)
      }
      setYears(arrayOfYears.sort())
      if (yearUntil) {
        const newListSince = arrayOfYears.filter(year => year <= yearUntil)
        setListYearsSince(newListSince.sort())
      } else {
        setListYearsSince(arrayOfYears.sort())
      }
      getYearUntil(yearSince, arrayOfYears.sort())
    } else {
      getYearRate(getOlderVintage(projects?.results))
      dispatch(setYearSince(getOlderVintage(projects?.results)))
      dispatch(setYearUntil(date))
    }
  }, [yearSince])

  return (
    <div data-testid='year-filter' className={styles['year-filter']}>
      <div className={styles['year-container']}>
        <p className={styles['label']}>{t('filters.yearSince')}</p>
        <Select
          className={styles['select-date']}
          value={yearSince}
          onSelect={handleChangeSince}
          dropdownStyle={{ zIndex: 2000 }}
        >
          {listYearsSince.map((year) => (
            <Option value={year} key={'since' + year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles['year-container']}>
        <p className={styles['label']}>{t('filters.yearUntil')}</p>
        <Select
          className={styles['select-date']}
          value={yearUntil}
          onSelect={handleChangeUntil}
          dropdownStyle={{ zIndex: 2000 }}
          showSearch={false}
          optionFilterProp='children'
        >
          {listYearsUntil.map((year) => (
            <Option value={year} key={'until' + year}>
              {year}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default Year
