/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCountries,
  setRefreshFilters,
  setSDGs,
  setSelectedStandardType,
  setSelectedTypes,
} from 'src/features/filters/filtersSlice'
import { getCategories, getCountries } from 'src/features/projects/projectsAPI'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { Categories } from 'src/interfaces/InterfaceGlobal'
import styles from './CategoriesPreFilter.module.scss'
import { useAppSelector } from 'src/app/hooks'

const CategoriesPreFilter: React.FC = () => {
  const [listOfCategoriesCountry, setListOfCategoriesCountry] = useState<
    Array<any>
  >([])
  const [listOfCountries, setListOfCountries] = useState<Array<any>>([])
  const [listOfCategories, setListOfCategories] = useState<Categories>()
  const projects = useAppSelector((state) => state.project.project)
  const loading = useAppSelector((state) => state.ui.loading)
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const listOfCategoriesRedux = useAppSelector(
    (state) => state.compensation.differentCategories
  )

  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    if (loading) {
      
      getCategories()
        .then((res) => {
          setListOfCategories(res.data)
        })
        .catch((e) => console.warn(e))

      getCountries()
        .then((res) => {
          setListOfCountries(res)
        })
        .catch((e) => console.warn(e))
      getTopTwo()
    }
  }, [loading])

  const getTopTwo = () => {
    let countryTops = []
    listOfCategories?.top_2_countries.forEach((el) => {
      const country = listOfCountries.find((country) => country.id === el.id)
      countryTops.push(country)
    })
    setListOfCategoriesCountry(countryTops)
  }

  const filterSDG = (id) => {
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth',
    })
    dispatch(setSDGs([id]))
    dispatch(setFiltersKeys(['SDG']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const filterCountry = (countryId) => {
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth',
    })
    const countrySelected = listOfCountries.find((el) => el.id == countryId)
    dispatch(setCountries([countrySelected]))
    dispatch(setFiltersKeys(['country']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const filterType = (type) => {
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth',
    })
    let typeObj
    listOfCategoriesRedux.forEach((el) => {
      el.options.forEach(el => {
        if(el.value == type){
          typeObj = el
        }
      })
    })
    typeObj && dispatch(setSelectedTypes([typeObj]))
    dispatch(setFiltersKeys(['projectType']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const filterStandard = (type) => {
    window.scroll({
      top: 100,
      left: 0,
      behavior: 'smooth',
    })
    dispatch(setSelectedStandardType([type.toLowerCase]))
    dispatch(setFiltersKeys(['standardType']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  const topStandardText = (standard: string) => {
    if (standard == 'CERCARBONO') return t('prefilter.standardCercarbono')
    if (standard == 'CDM') return t('prefilter.standardCDM')
    if (standard == 'VCS') return t('prefilter.standardVCS')
    if (standard !== 'VCS' && standard !== 'CDM' && standard !== 'CERCARBONO')
      return standard
  }

  const topTypeText = (topType: string) => {
    if (topType == 'Wind') return t('prefilter.standardWind')
    if (topType == 'Afforestation') return t('prefilter.standardAfforestation')
    if (topType == 'ARR') return t('prefilter.standardArr')
    if (topType == 'VCS') return t('prefilter.standardVCS')
    if (
      topType !== 'Wind' &&
      topType !== 'ARR' &&
      topType !== 'Afforestation' &&
      topType !== 'VCS'
    )
      return topType
  }

  const countProjects = (key, value) => {
    switch (key) {
      case 'country':
        return projects?.results.filter(
          (project) => project?.country?.id === value
        ).length

      case 'standard':
        return projects?.results.filter(
          (project) => project?.standard?.toLowerCase().includes(value?.toLowerCase())
        ).length

      case 'type':
        return projects?.results.filter(
          (project) => project.type === value ||
          project.energy_type === value
        ).length

      case 'sdg':
        return projects?.results.filter(
          (project) => project.sdg_compliances.some((sdg) => {
            if (sdg.id === value) {
              return true
            }
          })
        ).length

      default:
        return 0
    }
  }

  return (
    <div className={styles['contenedor']} data-testid='prefilter-test'>
      <div className={styles['box']}>
        <p className={styles['textTitle']}>{t('prefilter.categories')}</p>
        <Row gutter={[24, 32]}>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() =>
                  filterCountry(listOfCategories?.top_2_countries[0]?.id)
                }
              >
                <p
                  className={styles['category-text']}
                  title={
                    t('prefilter.projectIn') +
                    ' ' +
                    t(
                      `countries.${listOfCategories?.top_2_countries[0]?.code2}`
                    )
                  }
                >
                  {t('prefilter.projectIn')}{' '}
                  {t(
                    `countries.${listOfCategories?.top_2_countries[0]?.code2}`
                  )}
                </p>
                <span>
                  (
                  {countProjects(
                    'country',
                    listOfCategories?.top_2_countries[0]?.id
                  )}
                  )
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() =>
                  filterStandard(listOfCategories?.top_2_standards[0])
                }
              >
                <p
                  className={styles['category-text']}
                  title={topStandardText(listOfCategories?.top_2_standards[0])}
                >
                  {topStandardText(listOfCategories?.top_2_standards[0])}
                </p>
                <span>
                  (
                  {countProjects(
                    'standard',
                    listOfCategories?.top_2_standards[0]
                  )}
                  )
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() => filterSDG(listOfCategories?.top_2_sdg[0]?.id)}
              >
                <p
                  className={styles['category-text']}
                  title={
                    t('prefilter.sdgPretext') +
                    ' ' +
                    t(`SDG.${listOfCategories?.top_2_sdg[0]?.id}`)
                  }
                >
                  {t('prefilter.sdgPretext')}{' '}
                  {t(`SDG.${listOfCategories?.top_2_sdg[0]?.id}`)}
                </p>
                <span>
                  ({countProjects('sdg', listOfCategories?.top_2_sdg[0]?.id)})
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={listOfCategories?.top_energy_type !== '' ?
                  styles['category-tag'] : styles['category-tag-disabled']}
                onClick={() => listOfCategories?.top_energy_type !== '' && filterType(listOfCategories?.top_energy_type)}
              >
                <p
                  className={listOfCategories?.top_energy_type !== '' ? styles['category-text'] : styles['category-text-disabled']}
                  title={topTypeText(listOfCategories?.top_energy_type)}
                >
                  {listOfCategories?.top_energy_type !== '' ?
                    topTypeText(listOfCategories?.top_energy_type) : t('filters.energy-type')}
                </p>
                {listOfCategories?.top_energy_type !== '' &&
                <span>
                  ({countProjects('type', listOfCategories?.top_energy_type)})
                </span>
                }
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>

          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() =>
                  filterCountry(listOfCategories?.top_2_countries[1]?.id)
                }
              >
                <p
                  className={styles['category-text']}
                  title={
                    t('prefilter.projectIn') +
                    ' ' +
                    t(
                      `countries.${listOfCategories?.top_2_countries[1]?.code2}`
                    )
                  }
                >
                  {t('prefilter.projectIn')}{' '}
                  {t(
                    `countries.${listOfCategories?.top_2_countries[1]?.code2}`
                  )}
                </p>
                <span>
                  (
                  {countProjects(
                    'country',
                    listOfCategories?.top_2_countries[1]?.id
                  )}
                  )
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() =>
                  filterStandard(listOfCategories?.top_2_standards[1])
                }
              >
                <p
                  className={styles['category-text']}
                  title={topStandardText(listOfCategories?.top_2_standards[1])}
                >
                  {topStandardText(listOfCategories?.top_2_standards[1])}
                </p>
                <span>
                  (
                  {countProjects(
                    'standard',
                    listOfCategories?.top_2_standards[1]
                  )}
                  )
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() => filterSDG(listOfCategories?.top_2_sdg[1]?.id)}
              >
                <p
                  className={styles['category-text']}
                  title={
                    t('prefilter.sdgPretext') +
                    ' ' +
                    t(`SDG.${listOfCategories?.top_2_sdg[1]?.id}`)
                  }
                >
                  {t('prefilter.sdgPretext')}{' '}
                  {t(`SDG.${listOfCategories?.top_2_sdg[1]?.id}`)}
                </p>
                <span>
                  ({countProjects('sdg', listOfCategories?.top_2_sdg[1]?.id)})
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
          <Col lg={6} span={24}>
            <div className={styles['button-container']}>
              <div
                data-testid='category-tag'
                className={styles['category-tag']}
                onClick={() => filterType(listOfCategories?.top_type)}
              >
                <p
                  className={styles['category-text']}
                  title={topTypeText(listOfCategories?.top_type)}
                >
                  {topTypeText(listOfCategories?.top_type)}
                </p>
                <span>
                  ({countProjects('type', listOfCategories?.top_type)})
                </span>
              </div>
              <div className={styles['first-clone']}></div>
              <div className={styles['last-clone']}></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CategoriesPreFilter
