import styles from './WhatsNewBanner.module.scss'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { Col, Row } from 'antd'
import NeedMoreCredits from './NeedMoreCredits/NeedMoreCredits'
import FootprintCalculator from './FootprintCalculator/FootprintCalculator'

interface Props {
  banner: 'RFP' | 'FOOTPRINT'
}

const WhatsNewBanner: React.FC<Props> = ({ banner }) => {
  const { t } = useTranslation()

  const handleBanner = () => {
    switch (banner) {
      case 'RFP':
        return<NeedMoreCredits />
      case 'FOOTPRINT':
        return <FootprintCalculator/>
      default:
        return <></>

    }
  }

  return (
    <div className={styles['container']}>
      <Row className={styles['banner']}>
        <Col className={styles['body']}>
          <Row>
            <div className={styles['cardTag']}>
              <span>{t('whatsNewBanner.title')}</span>
            </div>
          </Row>
          <Row className={styles['body']}>
            {handleBanner()}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default WhatsNewBanner
