import React from 'react'
import { Checkbox } from 'antd'
import styles from './ProjectType.module.scss'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import {
  setIsReset,
  setRefreshFilters,
  setSelectedTypes,
} from 'src/features/filters/filtersSlice'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

interface Props {
  types: Array<any>
}

const ProjectType: React.FC<Props> = ({ types }) => {
  const dispatch = useDispatch()
  const typesSelected = useAppSelector((state) => state.filters.selectedTypes)

  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const isCheckedType = (type) => {
    const isIn = typesSelected.find(el => el.id == type.id)
    if(isIn){
      return true
    }else{
      return false
    }
  }

  const onChangeType = (type: any) => {
    if(!typesSelected){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_TYPE'))
    }
    dispatch(setIsReset(true))
    const isIn = typesSelected?.find((el) => el === type)
    if (isIn) {
      const newFilter = typesSelected.filter((el) => el !== type)
      dispatch(setSelectedTypes(newFilter))
    } else {
      dispatch(setSelectedTypes([...typesSelected, type]))
    }
    dispatch(setFiltersKeys(['projectType']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  return (
    <div data-testid='type-filter' className={styles['type-filter']}>
      {types?.map((type, index) => {
        return (
          <Checkbox
            className={
              isCheckedType(type)
                ? styles['text-selected']
                : styles['text']
            }
            checked={isCheckedType(type)}
            onClick={() => onChangeType(type)}
            key={index}
          >
            {type.value}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default ProjectType
