import styles from './UploadProject.module.scss'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { Col, Row } from 'antd'
import { useAppSelector } from 'src/app/hooks'

const UploadProject: React.FC = () => {
  const { t } = useTranslation()
  const projects = useAppSelector((state) => state.project.project)
  const uploadProjects = () => {
    window.location.href = 'https://climatetrade.com/contacto/'
  }

  return (
    <>
      {projects && (
        <Row data-testid='slider' justify='center'>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            
            <div className={styles['contenedor']}>
              <div className={styles['degrade']}></div>
              <Row justify='center'>
                <Col span={20} xl={17}>
                  <div style={{ zIndex: 1 }}>
              
                    <p className={styles['developers']}>{t('upload.developers')}</p>

                    <p className={styles['text']}>{t('upload.developersLine1')}</p>

                    <p className={styles['text']}>{t('upload.developersLine2')}</p>

                    <p className={styles['text']}>{t('upload.developersLine3')}</p>

                    <button
                      className={styles['button-upload']}
                      onClick={uploadProjects}
                      id={`btn-upload`}
                    >
                      <span className={styles['textButton']}>{t('upload.uploadProject')}</span>
                    </button>
                  </div>
                </Col>
              </Row>
              
            </div>
          </Col>
        </Row>
      )}
    </>
  )
}

export default UploadProject
