import Image from 'next/image'
import React, { useMemo } from 'react'
import goals from 'src/assets/img/goals.png'
import carbonCircle from 'src/assets/img/carbonCircle.png'
import Cookie from 'js-cookie'
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'

const CardPortfolio = ({ portfolio }) => {
  const { i18n,t } = useTranslation()

  const currencyCode = useAppSelector((state) => state.compensation.currency_code)
  const currencies = useAppSelector((state) => state.compensation.currencies)
  const currencySymbol = useMemo(() => currencies.find(c=>c.code===currencyCode)?.symbol,
    [currencyCode,currencies])
  const image = portfolio.images?.[0]?.image
  const handlePortfolioCardClick = () => {
    Cookie.remove('_p_id', { expires: 720 })
    Cookie.remove('_p_name', { expires: 720 })
    Cookie.remove('_p_cover', { expires: 720 })
    Cookie.remove('_p_c_name', { expires: 720 })
    Cookie.remove('_p_kind', { expires: 720 })
    Cookie.remove('myCookies', { expires: 720 })
    Cookie.set('_p_id', `${portfolio.id}`, { expires: 720 })
    Cookie.set('_p_name', portfolio.name, { expires: 720 })
    Cookie.set('_p_cover', image, { expires: 720 })

  }
  const price = portfolio.final_unit_price?.total_amount || portfolio.price

  return (
    <div style={window.innerWidth > 692 ? {
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '320px',
    }:{}}>
      <a
        href={`${window.location.origin}/projects/${i18n.language}/${portfolio.slug}?id=${portfolio.id}&type=portfolio`}
        onClick={handlePortfolioCardClick}
      >
        <div className='ProjectCard_module_card__79bdb06f'
          style={{ backgroundImage: `url('${image}')` }}>
          <div className='ProjectCardBadges_module_container__cab73a0d'>
            {!portfolio.available_stock &&<div className='WarningBadge_module_outOfStockContainer__57dc9a88'>
              <span className='WarningBadge_module_spanStock__57dc9a88'>
                {t('card.outOfStock')}
              </span>
            </div>}
          </div>
          <div className='ProjectCard_module_content__79bdb06f'>
            <div className='ProjectKindContent_module_content__5d8055a0'>
              <div className='ProjectKindContent_module_centered__5d8055a0'>
                <Image className='ProjectKindContent_module_image__5d8055a0' alt='circle-compensation' {...carbonCircle}/>
                <span className='ProjectKindContent_module_kind__5d8055a0'>
                  {t('card.portfolio')}
                </span>
              </div>
            </div>
            <div className='ProjectNameContent_module_content__7e519460'>
              <span className='ProjectNameContent_module_name__7e519460'>
                {portfolio.commercial_name}
              </span>
            </div>
            <div className='ProjectSdgContent_module_content__7427d94c'>
              <span className='ProjectSdgContent_module_price__7427d94c'>
                {currencySymbol}{price?.toFixed?.(2)}
              </span>
              <div className='ProjectSdgContent_module_sdgContent__7427d94c'>
                <Image alt='project-sdg' {...goals}
                  className='ProjectSdgContent_module_sdgImage__7427d94c' />
                <span className='ProjectSdgContent_module_sdgNumber__7427d94c'>
                  {portfolio.sdg_count}
                </span>
              </div>
            </div>
          </div>
          <div className='ProjectCard_module_mask__79bdb06f' data-background-image={image}>
          </div>
        </div>
      </a>
    </div>
  )
}

export default CardPortfolio
