import { Skeleton } from 'antd'
import React from 'react'
import styles from './SkeletonPreFiltersMobile.module.scss'

const SkeletonPreFiltersMobile: React.FC = () => {
  return (
    <div>
      <div className={styles['avatarInputSkeletonMobile']}>
        <Skeleton.Avatar active size='default' shape='circle' />
        &nbsp;
        <Skeleton.Input active size='default' />
      </div>
    </div>
  )
}

export default SkeletonPreFiltersMobile
