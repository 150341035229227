import React from 'react'
// Styles
import styles from './ProjectsOptions.module.scss'
// Components
import { Switch } from 'antd'
// Hooks
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'
import { setRefreshFilters, setWithoutStock } from 'src/features/filters/filtersSlice'
import { useDispatch } from 'react-redux'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const ProjectOptions: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const withoutStock = useAppSelector((state) => state.filters.withoutStock)
  const refreshFilters = useAppSelector((state) => state.filters.refreshFilters)

  const onChangeWithoutStock = () => {
    if(withoutStock){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_WITHOUT_STOCK'))
    }
    dispatch(setWithoutStock(!withoutStock))
    dispatch(setFiltersKeys(['projectOptions']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
    
  return(
    <div className={styles['filter-container']}>
      <p>{t('filters.without-stock')}</p>
      <Switch checked={withoutStock} onChange={onChangeWithoutStock} />
    </div>
  )
}

export default ProjectOptions