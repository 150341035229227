import React, { useEffect, useState } from 'react'
import styles from './FilteredItemsList.module.scss'
import { SuggesterFilterItem } from 'src/interfaces'
import FilteredSolrItem from './FilteredSolrItem/FilteredSolrItem'
import FilteredSolrListHeader from './FilteredSolrListHeader/FilteredSolrListHeader'

interface FilteredItemsListProps {
  text: string
  filteredItems: SuggesterFilterItem[]
  handleSearch: (send?: string) => void
}

const FilteredItemsList: React.FC<FilteredItemsListProps> = ({ text, filteredItems, handleSearch }) => {
  const [translatePosition, setTranslatePosition] = useState<string>('-16%')

  useEffect(() => {
    const positions = {
      '0': '-23%',
      '1': '-47%',
      '2': '-30%',
      '3': '-23%',
      'default': '-16%'
    }

    const length = filteredItems.length
    const position = positions[length] || positions.default
    setTranslatePosition(position)
  }, [filteredItems])

  return (
    <div className={styles.container}>
      <div
        className={styles.content}
        style={{ transform: `translate(-50%, ${translatePosition})` }}
      >
        {/* Header */}
        <FilteredSolrListHeader
          text={text}
          length={filteredItems.length}
          handleSearch={(send) => handleSearch(send)}
        />

        {/* List items */}
        {filteredItems.map((item, idx) => (
          <a
            key={idx}
            href={item.url}
            target={item.type !== 'results' && '_blank'}
            className='ignore-all'
          >
            <FilteredSolrItem
              item={item}
              text={text}
            />
          </a>
        ))}
      </div>
    </div>
  )
}

export default FilteredItemsList
