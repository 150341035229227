/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styles from './Stock.module.scss'
import { InputNumber, Slider } from 'antd'
import { useTranslation } from 'next-i18next'
import {
  setRefreshFilters,
  setStock,
  setStockChange,
} from 'src/features/filters/filtersSlice'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { validateOnlyNumbers } from 'src/helpers/utils'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const Stock: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const stock = useAppSelector((state) => state.filters.stock)
  const stockChange = useAppSelector((state) => state.filters.stockChange)
  const maxStock = useAppSelector((state) => state.compensation.maxStock)
  const loadingSliderStock = useAppSelector((state) => state.ui.loadingSliderStock)

  const [sliderValue, setSliderValue] = useState<Array<number>>([
    mapToScale(stock[0], 0, maxStock + 1), mapToScale(stock[1], 0, maxStock + 1)
  ])

  useEffect(() => {
    setSliderValue([mapToScale(stock[0], 0, maxStock + 1), mapToScale(stock[1], 0, maxStock + 1)])
  }, [loadingSliderStock])

  let timeoutFirst = null
  const onChangeFirstValue = (value) => {
    clearTimeout(timeoutFirst) // Clear any previous timeout
    timeoutFirst = setTimeout(() => {
      if (value > 0 && validateOnlyNumbers(value.toString())) {
        dispatch(setStock([parseFloat(value), stock[1]]))
      } else {
        dispatch(setStock([0, stock[1]]))
      }
      const inverseLogValue = inverseLogSlider(value, maxStock)
      setSliderValue([
        mapToScale(inverseLogValue, 0, maxStock + 1),
        sliderValue[1]
      ])
      if (value == 0) {
        dispatch(setStockChange(false))
      } else {
        if(!stockChange){
          dispatch(checkCookies())
          dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_STOCK'))
        }
        dispatch(setStockChange(true))
      }
      dispatch(setRefreshFilters(!refreshFilters))
    }, 800)
  }

  let timeoutSecond = null
  const onChangeSecondValue = (value) => {
    clearTimeout(timeoutSecond)
    timeoutSecond = setTimeout(() => {
      if (value >= stock[0] && validateOnlyNumbers(value?.toString())) {
        const inverseLogValue = inverseLogSlider(value, maxStock)
        dispatch(setStock([stock[0], parseFloat(value)]))
        setSliderValue([
          sliderValue[0],
          mapToScale(inverseLogValue, 0, maxStock + 1)
        ])
        dispatch(setFiltersKeys(['stock']))
        dispatch(setRefreshFilters(!refreshFilters))
        if (value == maxStock + 1) {
          dispatch(setStockChange(false))
        } else {
          if(!stockChange){
            dispatch(checkCookies())
            dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_STOCK'))
          }
          dispatch(setStockChange(true))
        }
      }
    }, 800)
  }

  function logSlider(value: number, max: number): number {
    const minp = 0
    const maxp = maxStock + 1
    const minv = 0
    const maxv = Math.log(max + 1)
    const scale = (maxp - minp) / (maxv - minv)

    return Math.floor(Math.exp((value - minp) / scale + minv)) - 1
  }

  function inverseLogSlider(value: number, max: number): number {
    const minp = 0
    const maxp = maxStock + 1
    const minv = 0
    const maxv = Math.log(max + 1)
    const scale = (maxp - minp) / (maxv - minv)

    return (Math.log(value + 1 - minv) * scale + minp)
  }

  function mapToScale(number, minBound, maxBound) {
    // Calculate the range of the bounds
    const range = maxBound - minBound

    // Calculate the ratio of the number within the bounds
    const ratio = (number - minBound) / range

    // Scale the ratio to a value between 1 and 100
    const scaledRatio = ratio * 99 + 1
    // const scaledRatio = (ratio * 100) / ((maxBound - minBound) / 100);

    // Return the scaled ratio rounded to the nearest integer
    return Math.round(scaledRatio)
  }

  function inverseMapToScale(scaledRatio, minBound, maxBound) {
    const range = maxBound - minBound
    const number = ((scaledRatio - 1) / 99) * range + minBound
    return number
  }



  const onChangeRange = (value) => {
    const logValue = value.map(v => logSlider(v, maxStock))
    dispatch(setStock(logValue))
    setSliderValue([
      mapToScale(value[0], 0, maxStock + 1),
      mapToScale(value[1], 0, maxStock + 1)
    ])

    dispatch(setFiltersKeys(['stock']))
    dispatch(setRefreshFilters(!refreshFilters))
    if (logValue[0] === 0 && logValue[1] === maxStock - 1) {
      dispatch(setStockChange(false))
    } else {
      if(!stockChange){
        dispatch(checkCookies())
        dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_STOCK'))
      }
      dispatch(setStockChange(true))
    }
  }

  return (
    <div data-testid='stock-filter' className={styles['stock-filter-container']}>
      <div className={styles['input-container']}>
        <Slider
          tooltipVisible={false}
          trackStyle={[{ backgroundColor: '#1C64F2' }]}
          handleStyle={[{ border: 'solid 2px #1C64F2' }]}
          className={styles['slider']}
          range={{ draggableTrack: true }}
          value={[inverseMapToScale(sliderValue[0], 0, maxStock + 1), inverseMapToScale(sliderValue[1], 0, maxStock + 1)]}
          onChange={(value) => {
            onChangeRange(value)
          }}
          max={maxStock + 1}
        />
      </div>

      <div className={styles['container']}>
        <div className={styles['input-container']}>
          <p className={styles['text']}>{t('filters.from')}</p>
          <InputNumber
            className={styles['stock-container']}
            data-testid='price-since-test'
            type='number'
            controls={false}
            value={stock[0]}
            step='0'
            onChange={(value) => onChangeFirstValue(value)}
            onPressEnter={(e) => e.preventDefault()}
            id={`price-since`}
          />
        </div>
        <div className={styles['input-container']}>
          <p className={styles['text']}>{t('filters.to')}</p>
          <InputNumber
            className={styles['stock-container']}
            data-testid='price-until-test'
            type='number'
            controls={false}
            value={stock[1] == null ? maxStock : stock[1]}
            onChange={(value) => onChangeSecondValue(value)}
            onPressEnter={(e) => e.preventDefault()}
            id={`price-until`}
            max={maxStock + 1}
            step='0'
          />
        </div>
      </div>
    </div>
  )
}

export default Stock
