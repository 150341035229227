import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { getNews } from '../../../helpers/utils'
import styles from './ExitIntentNotLogged.module.scss'
import { Col, Row } from 'antd'
import newsImg from '../../../assets/img/exitIntentNews1.png'
import NewsSubscriptionForm from '../NewsSubscriptionForm/NewsSubscriptionForm'

interface Props {
  onClose: Function;
}

const ExitIntentNotLogged: React.FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation()
  const [newsText, setNewsText] = useState<string>('')

  useEffect(() => {
    setNewsText(getNews(i18n.language, 1))
  }, [i18n])

  return (
    <Row>
      <Col>
        <Row gutter={[0, 16]} className={styles['container']}>
          <Col span={24}>
            <div className={styles['gradient']}></div>

            <Row wrap={false} justify='space-between'>
              <Col flex={'auto'} className={styles['info']}>
                <h1 className={styles['title']}>
                  {t('exitIntentPopup.notLogged.title')}
                </h1>
              </Col>
              <Col className={styles['newsCol']}>
                <div className={styles['newsContainer']}>
                  <Row>
                    <div className={styles['newsTag']}>
                      <span>CLIMATETRADE NEWS</span>
                    </div>
                  </Row>
                  <Row>
                    <h2 className={styles['subtitle']}>{newsText}</h2>
                  </Row>
                  <Row>
                    <img src={newsImg.src} alt='project list image' />
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={styles['form']}>
          <Col span={24}>
            <NewsSubscriptionForm onFinish={onClose} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ExitIntentNotLogged
