/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

// Syles
import styles from './Country.module.scss'
// Hooks
import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
// Types
import { CountryObj } from 'src/interfaces/InterfaceGlobal'
// Components
import { Checkbox } from 'antd'
// Redux
import {
  setCountries,
  setQueryWrite,
  setRefreshFilters
} from 'src/features/filters/filtersSlice'
// Hooks
import { useAppSelector } from 'src/app/hooks'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { queryFilterWriter } from 'src/helpers/utils'
import { useRouter } from 'next/router'

interface Props {
    continent: string
}

const Country: React.FC<Props> = ({ continent }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const countries = useAppSelector((state) => state.filters.countries)
  const differentCountries = useAppSelector(
    (state) => state.compensation.differentCountries
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )

  const [listOfCountries, setListOfCountries] = useState<Array<CountryObj>>()

  const onChangeCountry = (country: any) => {
    dispatch(setQueryWrite(true))
    if (!countries) {
      dispatch(checkCookies())
      dispatch(
        trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_COUNTRY')
      )
    }
    const isIn = countries?.find((el) => el.id === country.id)
    if (isIn) {
      const newFilter = countries.filter((el) => el.id !== country.id)
      dispatch(setCountries(newFilter))
    } else {
      dispatch(setCountries([...countries, country]))
    }
    dispatch(setFiltersKeys(['country']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    queryFilterWriter('country', countries, null, 'id', 'code2', router)
  }, [countries])

  useEffect(() => {
    const countries = differentCountries.filter(
      (el) => el.continent == continent
    )
    setListOfCountries(countries)
  }, [continent])

  const isCheckedCountry = (id) => {
    const isIn = countries.find((el) => el.id == id)
    if (isIn) {
      return true
    } else {
      return false
    }
  }

  return (
    <div data-testid='country-filter' className={styles['country-filter']}>
      {listOfCountries?.map((country, index) => {
        return (
          <Checkbox
            className={
              countries.includes(country)
                ? styles['country-text-selected']
                : styles['country-text']
            }
            checked={isCheckedCountry(country.id)}
            onClick={() => onChangeCountry(country)}
            key={index}
          >
            <img
              src={country.flag}
              className={styles['country-img']}
            />{' '}
            {t(`countries.${country.code2}`)}
          </Checkbox>
        )
      })}
    </div>
  )
}

export default Country
