/* eslint-disable react-hooks/exhaustive-deps */
import styles from './CardDidYouKnowThat.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { ArrowRightOutlined, BulbFilled } from '@ant-design/icons'
import tree from '../../assets/img/Vector.svg'
import Image from 'next/image'
interface Props {
  cardNumber: number;
}

const cardList = [
  {
    id: 1,
    text:
      'Did you know that offsetting 1 tCO2 is the equivalent of planting 6 trees',
    url: 'https://climatetrade.com/carbon-emissions-offsetting/',
    esUrl: 'https://climatetrade.com/es/compensacion-de-emisiones/',
  },
  {
    id: 2,
    text:
      'Did you know that products with ESG claims grew 40% more than others',
    url: '',
    esUrl: '',
  },
  {
    id: 3,
    text:
      'Did you know that the average annual carbon footprint of a person is between 7 and 16 tCO2',
    url:
      'https://market.climatetrade.com/carbon-footprint-calculator/lifestyle',
    esUrl: '',
  },
  {
    id: 4,
    text:
      'Did you know that adding a sustainability option to your product doubles yours conversion rate',
    url: '',
    esUrl: '',
  },
  {
    id: 5,
    text:
      'Did you know that 3 out of 5 travelers want sustainable choice for travel',
    url: '',
    esUrl: '',
  },
  {
    id: 6,
    text:
      'Did you know that companies buying carbon credits reduced their emissions twice as fast as those that do not buy carbon',
    url: '',
    esUrl: '',
  },
  {
    id: 7,
    text:
      'Did you know that 100 tCO2 are equivalent to the size of 100 semi olympic swimming pools',
    url: '',
    esUrl: '',
  },
  {
    id: 8,
    text:
      'Water ecosystems are polluted by more than 140 millions of tons of plastic',
    url: '',
    esUrl: '',
  },
  {
    id: 9,
    text:
      '80% of investors consider sustainability criteria in their investment decisions',
    url: '',
    esUrl: '',
  },
]

const CardDidYouKnowThat = ({ cardNumber }: Props) => {
  const { t, i18n } = useTranslation()
  const [card, setCard] = useState(null)
  const [cardText, setCardText] = useState<string>('')
  const [cardLink, setCardLink] = useState<string>('')

  const [fontSize, setFontSize] = useState<number>(28) // tamaño de fuente inicial
  const [lineHeight, setLineHeight] = useState<number>(32)
  const divRef = useRef(null)
  const pRef = useRef(null)

  useEffect(() => {
    const div = divRef.current
    const p = pRef.current

    const maxHeight = div.offsetHeight
    const maxWidth = div.offsetWidth

    let newFontSize = 28 // tamaño de fuente inicial
    let newLineHeight = 32
    while (p.offsetHeight > maxHeight || p.offsetWidth > maxWidth) {
      newFontSize = newFontSize - 2 // reducir el tamaño de fuente
      newLineHeight = newLineHeight - 2
      p.style.fontSize = newFontSize + 'px' // establecer el tamaño de fuente en el elemento
      p.style.lineHeight = newLineHeight + 'px'
    }

    if (fontSize !== newFontSize) {
      setFontSize(newFontSize)
      setLineHeight(newLineHeight)
    }
  }, [cardText])

  useEffect(() => {
    const card = cardList.find((c) => c.id === cardNumber)
    setCard(card)
    setCardLink(
      i18n.language == 'es' && card?.esUrl !== '' ? card?.esUrl : card?.url
    )
    setCardText(t(`cardDidYouKnowThat.${cardNumber}`))
  }, [cardNumber])

  const handleClick = (event) => {
    if (cardLink === '') {
      event.preventDefault()
    }
  }

  return (
    <div className={styles['card-container']}>
      <div className={styles['card-content']}>
        <a
          href={cardLink}
          onClick={handleClick}
          target='_blank'
          rel='noreferrer'
          className={cardLink === '' ? styles['disabled'] : ''}
        >
          <div className={styles['card']}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div className={styles['title-container']}>
                <BulbFilled style={{ color: '#ffffff', marginRight: '8px' }} />

                <span className={styles['title']}>
                  {t('cardDidYouKnowThat.didYouKnowThat')}
                </span>
              </div>
              <div ref={divRef} className={styles['text-container']}>
                <p
                  ref={pRef}
                  className={styles['text']}
                  style={{
                    fontSize: `${fontSize}px`,
                    lineHeight: `${lineHeight}px`,
                  }}
                >
                  {cardText}
                </p>
                {card?.id === 1 && (
                  <div className={styles['tag']}>
                    <Image src={tree.src} alt='tree' width={22} height={27} />
                    <span className={styles['tag-text']}>+6</span>
                  </div>
                )}
              </div>
            </div>
            <div className={styles['learn-more']}>
              {card?.url && (
                <span>
                  {card?.id === 1
                    ? t('cardDidYouKnowThat.learnMore')
                    : t('cardDidYouKnowThat.checkYours')}{' '}
                  {''}
                  <ArrowRightOutlined style={{ color: '#ffffff' }} />
                </span>
              )}
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default CardDidYouKnowThat
