import React from 'react'
// Styles
import styles from './MinimumPurchase.module.scss'
// Components
import { Switch } from 'antd'
// Hooks
import { useTranslation } from 'next-i18next'
import { useAppSelector } from 'src/app/hooks'
import { setMinimumPurchase, setRefreshFilters } from 'src/features/filters/filtersSlice'
import { useDispatch } from 'react-redux'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'

const MinimunPurchase: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const minimumPurchase = useAppSelector((state) => state.filters.minimumPurchase)
  const refreshFilters = useAppSelector((state) => state.filters.refreshFilters)

  const onChangeMinimunPurchase = () => {
    if(minimumPurchase){
      dispatch(checkCookies())
      dispatch(trackEvent('MARKET_HOME', 'ACTION_CLICK', 'FILTER_MINIMUM_STOCK'))
    }
    dispatch(setMinimumPurchase(!minimumPurchase))
    dispatch(setFiltersKeys(['stock']))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  return(
    <div className={styles['filter-container']}>
      <p>{t('filters.minimum-purchase')}</p>
      <Switch checked={minimumPurchase} onChange={onChangeMinimunPurchase} />
    </div>
  )
}

export default MinimunPurchase
