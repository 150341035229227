import { Row, Col, Skeleton } from 'antd'
import React from 'react'
import styles from './SkeletonCategoryProjects.module.scss'

const SkeletonCategoryProjects: React.FC = () => {
  const createCards = () => {
    const cards = []
    for (let i = 0; i < 6; i++) {
      cards.push(
        <Col key={i.toString()}>
          <div className={styles['cardContainer']}>
            <div className={styles['cardSkeleton']}>
              <br />
              <br />
              <br />
              <br />
              <Skeleton active avatar paragraph={{ rows: 3 }} />
            </div>
          </div>
        </Col>
      )
    }
    return cards
  }

  return (
    <Row
      gutter={[32, 32]}
      className={styles['mobile-list']}
    >
      {createCards()}
    </Row>
  )
}

export default SkeletonCategoryProjects
